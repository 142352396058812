// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../api';

import MomentActivityStore from './MomentActivityStore';

class AIConversationStore {
  aiConversation = null;

  attemptsInfo = null;

  attemptInfo = null;

  isConversationLoading = false;

  userInfo = {};

  getPublishedAIConversation = async ({ contentAreaId, isCoachOrAdmin, userId }) => {
    try {
      this.isConversationLoading = true;
      const {
        data: { aiConversation, attemptsInfo, userInfo },
      } = await Api.getPublishedAIConversation({ contentAreaId, isCoachOrAdmin, userId });

      this.aiConversation = aiConversation;
      this.attemptsInfo = attemptsInfo;
      this.isConversationLoading = false;
      this.userInfo = userInfo;

      return {
        aiConversation,
        attemptsInfo,
        userInfo,
      };
    } catch (err) {
      throw new Error(err);
    }
  };

  getDraftAIConversation = async (contentAreaId) => {
    try {
      this.isConversationLoading = true;

      if (!this.aiConversation) {
        const {
          data: { aiConversation, maxScore, maxScorePerStages },
        } = await Api.getDraftAIConversation(contentAreaId);
        aiConversation.maxScore = maxScore;
        aiConversation.stages.forEach((stage) => {
          const st = maxScorePerStages.find(({ uuid }) => uuid === stage.uuid);
          if (st) {
            stage.maxScore = st.maxScore;
          }
        });
        this.aiConversation = aiConversation;
        this.attemptsInfo = [];
        this.isConversationLoading = false;
      }

      return {
        aiConversation: this.aiConversation,
      };
    } catch (err) {
      throw new Error(err);
    }
  };

  getDebriefInfo = async ({ contentAreaId, attemptId, isCoachOrAdmin, userId }) => {
    try {
      const { data } = await Api.getDebriefInfo({
        contentAreaId,
        attemptId,
        isCoachOrAdmin,
        userId,
      });

      this.attemptInfo = data;

      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  sendMessage = async (text, options) => {
    const {
      lifelineCount,
      missedClicks,
      duration: durationFromSystemMoment,
    } = MomentActivityStore;
    try {
      const { data } = await Api.sendMessage(text, {
        ...options,
        duration: durationFromSystemMoment || options.duration,
        lifelinesUsed: lifelineCount,
        systemErrors: missedClicks,
      });
      return data;
    } catch (err) {
      throw new Error('Error while sending message', err);
    }
  };

  completeAttempt = async (payload, options) => {
    const {
      lifelineCount,
      missedClicks,
      duration: durationFromSystemMoment,
    } = MomentActivityStore;
    try {
      await Api.completeAttempt({
        ...payload,
        duration: durationFromSystemMoment || payload.duration,
        lifelinesUsed: lifelineCount,
        systemErrors: missedClicks,
      }, options);
    } catch (err) {
      throw new Error('Error in finish Attempt', err);
    }
  };

  getConversationsByUsers = async (
    userIds,
  ) => {
    try {
      const { data: conversationsByUsers }
      = await Api.getConversationsByUsers(userIds);
      this.conversationsByUsers = conversationsByUsers;
      return this.conversationsByUsers;
    } catch (err) {
      throw new Error('Error in get conversations by team assignment', err);
    }
  };
}

decorate(AIConversationStore, {
  aiConversation: observable,
  isConversationLoading: observable,
  getPublishedAIConversation: action,
  getDraftAIConversation: action,
  getDebriefInfo: action,
  sendMessage: action,
  completeAttempt: action,
  getConversationsByUsers: action,
  conversationsByUsers: observable,
});

export default new AIConversationStore();
